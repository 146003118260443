import { Link } from 'gatsby';
import { darken } from 'polished';
import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import '../../assets/scss/main.scss';

import { colors } from '../../styles/colors';
import config from '../../website-config';
import { SubscribeModal } from '../subscribe/SubscribeModal';
import { SiteNavLogo } from './SiteNavLogo';
import { Console } from 'node:console';

interface SiteNavProps {
  isHome?: boolean;
  isPost?: boolean;
  post?: any;
}

interface SiteNavState {
  showTitle: boolean;
}

class SiteNav extends React.Component<SiteNavProps, SiteNavState> {
  subscribe = React.createRef<SubscribeModal>();
  titleRef = React.createRef<HTMLSpanElement>();
  lastScrollY = 0;
  ticking = false;
  state = { showTitle: false };

  openModal = () => {
    if (this.subscribe.current) {
      this.subscribe.current.open();
    }
  };

  componentDidMount(): void {
    this.lastScrollY = window.scrollY;
    if (this.props.isPost) {
      window.addEventListener('scroll', this.onScroll, { passive: true });
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (!this.titleRef || !this.titleRef.current) {
      return;
    }

    if (!this.ticking) {
      requestAnimationFrame(this.update);
    }

    this.ticking = true;
  };

  update = () => {
    if (!this.titleRef || !this.titleRef.current) {
      return;
    }

    const direction = Boolean((window.scrollY-this.lastScrollY)>0);
    this.lastScrollY = window.scrollY;
    
    const trigger = this.titleRef.current.getBoundingClientRect().top;
    const triggerOffset = this.titleRef.current.offsetHeight + 165;    
    // show/hide post title show only when scrolling down and hide only when scrolling up  
    if ((this.lastScrollY > trigger + triggerOffset) && (direction)) {
      this.setState({ showTitle: true });
    } else if  ((this.lastScrollY < trigger + triggerOffset) && (!direction)){
      this.setState({ showTitle: false });
    }

    this.ticking = false;
  };

  render() {
    const { isHome = false, isPost = false, post = {} } = this.props;
    return (
      <>
        {config.showSubscribe && <SubscribeModal ref={this.subscribe} />}
        <nav css={SiteNavStyles}>
          <SiteNavLeft className="site-nav-left, flex-row">
            {!isHome && <SiteNavLogo />}
            <SiteNavContent css={[this.state.showTitle ? HideNav : HidePostTitle]}>
              <ul css={NavStyles} role="menu">
                {/* TODO: mark current nav item - add class nav-current */}
                <li role="menuitem">
                  <Link to="/">Home</Link>
                </li>
                <li role="menuitem">
                  <Link to="/about">About</Link>
                </li>
                <li role="menuitem">
                  <Link to="/tags/tutorials/">Tutorials</Link>
                </li>
              </ul>
              {isPost && (
                <NavPostTitle ref={this.titleRef} className="nav-post-title">
                  {post.title}
                </NavPostTitle>
              )}
            </SiteNavContent>
          </SiteNavLeft>
          <SiteNavRight className="flex-row">
            <SocialLinks css={[this.state.showTitle ? HideNav2 : '']}>
            <ul  className="icons">
                <li><a href="https://twitter.com/jcamargo_co" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="https://www.facebook.com/is.not.ossip" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.youtube.com/user/jcl087/videos" className="icon alt fa-youtube"><span className="label">Youtube</span></a></li>
                <li><a href="https://github.com/jonathancamargo" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
                <li><a href="https://www.linkedin.com/in/camargoj/" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
            </ul>           
            </SocialLinks>
            {config.showSubscribe && (
              <SubscribeButton id="subscribe-btn" onClick={this.openModal}>Subscribe</SubscribeButton>
            )}
          </SiteNavRight>
        </nav>
      </>
    );
  }
}

export const SiteNavMain = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
  /* background: color(var(--darkgrey) l(-5%)) */
  /*background: ${darken('0.05', colors.blue)};*/
  background: ${colors.blue};
  @media (max-width: 700px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const SiteNavStyles = css`  
  display: flex;  
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: space-between;
  font-size: 1.3rem;
`;

const SiteNavLeft = styled.div`
  display: flex;    
  flex-direction: row;
  flex-wrap: wrap;  
  align-items: center;  
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;    
`;

const SiteNavContent = styled.div`
  display: flex;  
  flex: 0 1 auto;
`;

const NavStyles = css`  
  display: flex;  
  flex: 0 1 auto;

  padding: 0;
  list-style: none;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  margin: 0;
  li {
    display: block;
    margin: 0;
    padding: 0;
  }

  li a {
    position: relative;
    display: block;
    padding: 12px 12px;
    color: #fff;
    opacity: 0.8;
    transition: opacity 0.35s ease-in-out;
  }

  li a:hover {
    text-decoration: none;
    opacity: 1;
    color: ${colors.highlight};
  }

  li a:before {
    content: '';
    position: absolute;
    right: 100%;
    bottom: 8px;
    left: 12px;
    height: 1px;
    background: #fff;
    opacity: 0.25;
    transition: all 0.35s ease-in-out;
  }

  li a:hover:before {
    opacity: 0.5;
  }
`;

const SiteNavRight = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  height: 64px;

  @media (max-width: 700px) {
    display: none;
  }
`;

const SocialLinks = styled.div`  
  display: flex;
  align-items: center;
  ul{
    margin: 0;

  }
`;

const SubscribeButton = styled.a`
  display: block;
  border: #fff 1px solid;
  margin: 0 0 0 10px;
  padding: 4px 10px;
  cursor: pointer;
  user-select: none;
  color: #fff;
  line-height: 1em;
  border-radius: 10px;
  opacity: 0.8;  

  &:active {
    color: desaturate(darken(_palette(highlight), 15), 5) !important;
  }

`;

const NavPostTitle = styled.div`
  visibility: hidden;
  top: 9px;
  color: #fff;
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: none;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);


  .dash {
    left: -25px;
  }

  .dash:before {
    content: '– ';
    opacity: 0.5;
  }
`;

const HideNav = css`
.nav-post-title {
  display: flex;
  visibility: visible;
  opacity: 1;
  //transform: translateY(0);
}  
ul {
    display:none;
    visibility: hidden;
    opacity: 0;
    // transform: translateY(-175%);
  }
  
`;
const HidePostTitle = css`  
  .nav-post-title {
    visibility: hidden;
    display:none;
    opacity: 1;
    //transform: translateY(0);
  }
`;



const HideNav2 = css`
  ul {
    display:none;
    visibility: hidden;
    opacity: 0;
  }
`;


export default SiteNav;
